import nowplayingService from '@/services/nowplayingService';
import {isError} from "vue-router/src/util/errors";
//import { FETCH_FAVORITES, FETCH_TAGS } from './actionTypes';
//import { SET_FAVORITES, SET_TAGS } from './mutationTypes';

export const state = {
    trackList: [],
    currentSong:[],
    nextSong:[],
    //searchText: ''
};

export const actions = {
    async fetchNowplaying({ commit }) {
        console.time("fetchNowplaying")
        console.log("%c fetchNowplaying" , 'background: blue; color: white')
        let nowplaying = await nowplayingService.get();
        return new Promise((resolve, reject) => {
            try{
                commit("setNowplaying",nowplaying);
                resolve();
            }catch (e) {
                reject();
            }

        });

    },
    resetSongs({commit }){
        commit("resetSongs");
    }


};
export const getters = {
    courrentSong : (state) => {
        return state.currentSong;
    },
    hasSongs : (state) => {
        return (!!Object.keys(state.currentSong).length);
    },
    getBackground : (state) =>{
        console.log(state.currentSong);
        if(state.currentSong)
            return state.currentSong.pochette
        else return "img/icon.png" ;
    },
};

export const mutations = {
    setNowplaying : (currentState, nowplaying) => {
        console.log("%c setNowplaying :", 'background: green; color: white',nowplaying);
        currentState.currentSong = {
        title: nowplaying.title,
        artist: nowplaying.artist,
        album: "",
        artwork: [
            {
              src: nowplaying.pochette
            }
        ],
        pochette: nowplaying.pochette,
      };
        currentState.trackList = nowplaying;
        currentState.nextSong = nowplaying;
    },

    resetSongs: (currentState)=>{
        currentState.currentSong=[]
        currentState.trackList=[]
        currentState.nextSong=[]

    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};