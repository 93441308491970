// audio.js

import { AudioContext } from 'standardized-audio-context';

const audio = {
    _audio: new Audio(),
    _context: new AudioContext(),
    _freq: new Uint8Array(),
    _source: null,
    _gain: null,
    _analyser: null,

    setupAudio() {
        console.log("setupAudio");
        if (this._source === null) this._source = this._context.createMediaElementSource(this._audio);

        if (this._gain === null) this._gain = this._context.createGain();
        this._source.connect(this._gain);
        this._gain.connect(this._context.destination);

        this._audio.addEventListener('canplaythrough', () => {
            this.playAudio();
        });

        if (this._analyser === null) this._analyser = this._context.createAnalyser();
        this._source.connect(this._analyser);

        if (this._context.state === 'suspended') this.unlockAudioContext(this._context);

        return this._audio;
    },

    getFreqData() {
        if (this._analyser === null) return 1;
        this._analyser.getByteFrequencyData(this._freq);
        return this._freq;
    },

    playAudio() {
        if (this._context.state === 'running') {
            this._freq = new Uint8Array(this._analyser.frequencyBinCount);
            this._audio.play().catch(e => {
                console.log("audio :" + e);
            });
        }
    },

    unlockAudioContext(audioCtx) {
        if (audioCtx.state === 'suspended') {
            var events = ['touchstart', 'touchend', 'mousedown', 'keydown'];
            var unlock = function unlock() {
                console.log("audioContext state: " + audioCtx.state);
                if (audioCtx.resume) audioCtx.resume().then(() => {
                    console.log("audioContext resume state: " + audioCtx.state);
                    if (audioCtx.state !== 'suspended') {
                        events.forEach(function (event) {
                            document.body.removeEventListener(event, unlock);
                        });
                    }
                });
            };
            events.forEach(function (event) {
                document.body.addEventListener(event, unlock, false);
            });
        }
    },

    setVolume(volume) {
        if (!this._gain) return;
        volume = parseFloat(volume) || 0.0;
        volume = (volume < 0) ? 0 : volume;
        volume = (volume > 1) ? 1 : volume;
        this._gain.gain.value = volume;
    },

    playSource(source) {
        this.stopAudio();
        this._audio.src = String(source || '');
        this._audio.crossOrigin = 'anonymous';
        this._audio.load();
    },

    stopAudio() {
        try {
            this._audio.pause();
        } catch (e) {
            console.log(e);
        }
    },
};

export default audio;