/**
 * Joujma  API handler
 */
import axios from 'axios';

import config from 'config';
import { getters } from './../store/playerConfig'

axios.interceptors.request.use( x => {
    // to avoid overwriting if another interceptor
    // already defined the same object (meta)
    x.meta = x.meta || {}
    x.meta.requestStartedAt = new Date().getTime();
    return x;
})

axios.interceptors.response.use( x => {
        console.log(`Execution time for: ${x.config.url} - ${ new Date().getTime() - x.config.meta.requestStartedAt} ms`)
        return x;
    },
    // Handle 4xx & 5xx responses
    x => {
        console.error(`Execution time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`)
        throw x;
    }
)

export default {
    // get now playing response
    config : getters["playerConfig/getConfig"],
    get(){
        let apiurl = config.api_url;
        return axios.get( "https://enkaradio.be/titrage" ).then( res => {
            const list = res.data ;
            console.log("Nowplaying Service :  get : ",list)
            return list;
        });
    },

    _parseNowplaying() {
        return undefined;
    }
}
